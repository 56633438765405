:root {
  --primary: #f2f2f2;
  --primary-light: #ffffff;
  --primary-dark: #ffffff;
  --button-primary: #B08654;
  --button-primary-light: #000;
}
html{margin: 0;float: left;width: 100%;}
body {
  background-color: var(--primary);
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  float: left;
  width: 100%;
}
.bg-dark {
  background-color: var(--primary-dark) !important;
}

.white-container {
  background-color: white;
  padding: 30px 30px 30px 30px;
  border-radius: 10px;
}

.container-title {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

.small-link {
  font-size: 13px;
}

.fill {
  width: 100%;
}

.menu-item-container {
  background-color: #F2F2F2;
  color: black;

  width: 100%;
  height: 50px;

  text-decoration: none;
  font-weight: 500;
  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 10px;
  border-radius: 10px;
}

.menu-item-container:hover {
  color: black;
}

.menu-item-container div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-cell {
  width: 40px;
}
.button-link {
  background: none!important;
  border: none;
  /*optional*/
  /*input has OS specific font-family*/
  color: #069;
  cursor: pointer;
}

.title {
  font-size: 30px;
  font-weight: 500;
}

#menuConfig {
  background-color: var(--button-primary);
  color: white;
  border-radius: 10px!important;
}

#menuConfig2 {
  background-color: var(--button-primary);
  color: white;
  border-radius: 10px!important;
}

#menuConfig3 {
  background-color: var(--button-primary);
  color: white;
  border-radius: 10px!important;
}

#leftMenusContainer .collapsed {
  background-color: #F2F2F2;
  color: black;
}

#leftMenusContainer #menuConfig svg{
  color: white;
}

#leftMenusContainer #menuConfig2 svg{
  color: white;
}

#leftMenusContainer #menuConfig3 svg{
  color: white;
}

#leftMenusContainer .collapsed svg{
  color: black !important;
}

.card {
  border-radius: 8px;
  border: none;
}

.card-big-text {
  font-size: 40px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-icon {
  width: 50px;
}

.Height100 {
  height: 100vh;
}
.table-responsive .form-select{padding: .375rem 1.2rem .375rem .5rem;background-position: right .1rem center;}
.table-responsive .dropdown-toggle{width: 100%;justify-content:left;text-align: left;position: relative}
.table-responsive .dropdown-toggle::after{position: absolute;right: 8px}

.dropMensagens {
  margin-bottom: 400px !important;
}

@media (min-width: 1200px) {
  .form-inline .input-group {
    width: 300px;
    margin-left: 30px;
  }
}
@media (max-width: 1199px) {
  .form-inline {
    display: block;
    margin-bottom: 10px;
  }
  .input-group {
    width: 100%;
  }
}

.navbar-header.col {
  padding: 0 !important;
}

.form-content {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
}

.editing-alert {
  margin-top: 35px;
  display: inline-block;
  font-weight: 500;
}

.required {
  color: red;
}

td svg {
  cursor: pointer;
}

td svg:hover {
  color: #444;
}

.loading-container {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.3);
}

.loading-container .spinner-border {
  width: 3rem;
  height: 3rem;
  color: var(--button-primary);
  border-width: 7px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
}

/* Buttons */
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn100 {
  width: 100%;
}

.btn-primary {
  background-color: var(--button-primary) !important;
  border-color: var(--button-primary) !important;
}

.btn-orange {
  background-color: var(--button-primary);
  color: white !important;
}

.btn-icon {
  margin-right: 10px;
  margin-top: 2px;
}

.btn-confirm {
  margin-left: 10px;
  margin-top: 30px;
  min-width: 150px;
  display: inline-block;
}

#label-upload-photo {
  cursor: pointer;
  padding: 16px 0;
  text-align: center;
  background: #f9f9f9;
  border-radius: 5px;
  width: 100%;
  /* Style as you please, it will become the visible UI component. */
}
#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.label-upload-photo {
  cursor: pointer;
  padding: 16px 0;
  text-align: center;
  background: #f9f9f9;
  border-radius: 5px;
  width: 100%;
  /* Style as you please, it will become the visible UI component. */
}
.upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.btn:disabled{background:#ccc!important; }

.hover-onclick{
  cursor: pointer;
}
.hover-onclick:hover{
  background-color: #f1f1f1;
}
.hover-onclick.active{background-color: #f1f1f1;}
nav .dropdown-menu{
  right: 0!important;
  left: auto!important;
}
.pwd-container{
  position: relative;
}
.pwd-container i{
  position: absolute;
  right: 10px;
  top:10px;
  cursor: pointer;
}
/* Tables */
.table-container {
  overflow-y: auto;
  max-width: 100%;
}

.table-list {
  background-color: white;
  border-radius: 10px;
  padding: 8px;
  border-collapse: unset;
}

.table th {
  border-bottom-width: 0px;
}

.table-status {
  color: white;
  text-align: center;
  border-radius: 10px;
  float: left;
  white-space: nowrap;
  padding: 1px 10px;
}

/* Header */
.nav-link userIcon {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin: -8px 0;
  float: left;
  margin-right: 10px;
}
.navbar .navbar-brand {
  color: #555;
  padding-left: 0;
  padding-right: 10px;
  font-family: "Merienda One", sans-serif;
}
.navbar .navbar-brand i {
  font-size: 20px;
  margin-right: 5px;
}

.navbar .nav-item i {
  font-size: 18px;
}
.navbar .dropdown-item i {
  font-size: 16px;
  min-width: 22px;
}
.navbar .nav-item.open > a {
  background: none !important;
}
.navbar .dropdown-menu {
  border-radius: 1px;
  border-color: #e5e5e5;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}
.navbar .dropdown-menu a {
  color: #777;
  padding: 8px 20px;
  line-height: normal;
}
.navbar .dropdown-menu a:hover,
.navbar .dropdown-menu a:active {
  color: #333;
}
.navbar .dropdown-item .material-icons {
  font-size: 21px;
  line-height: 16px;
  vertical-align: middle;
  margin-top: -2px;
}
.navbar .badge {
  color: #fff;
  background: #f44336;
  font-size: 11px;
  border-radius: 20px;
  position: absolute;
  min-width: 10px;
  padding: 4px 6px 0;
  min-height: 18px;
  top: 5px;
}
.navbar a.notifications,
.navbar a.messages {
  position: relative;
  margin-right: 10px;
}
.navbar a.messages {
  margin-right: 20px;
}
.navbar a.notifications .badge {
  margin-left: -8px;
}
.navbar a.messages .badge {
  margin-left: -4px;
}
.navbar .active a,
.navbar .active a:hover,
.navbar .active a:focus {
  background: transparent !important;
}


.dropdown-item {
  font-weight: 500;
}


.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
  justify-content: flex-end;
}

.pagination > .active > a{
  background-color: var(--button-primary) ;
  border-color: var(--button-primary) ;
  color: #fff;
}

.pagination > li > a{
  padding: 7px 16px;
  font-weight: 600;
  background-color: white;
  margin: 2px;
  border-radius: 20% !important;
  outline: none;
  cursor: pointer;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: var(--button-primary) ;
  border-color: var(--button-primary);
  outline: none ;
  color: white;
}

.pagination > li > a, .pagination > li > span{
  color: var(--button-primary)
}

.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset
}

.left-menu {
  background-color: #f9f9f9 !important;
  min-height: 100%;
}

.MuiIconButton-root{    padding: 0!important;}
.MuiInputBase-input{width: 100%;color: #212529!important;padding: 7px 0 6px!important;}
.MuiInputBase-root{width: 100%;border: 1px solid #ced4da;padding: 0 .2rem;}
.MuiInput-underline:after,.MuiInput-underline:before{border: 0!important;}

.dropDownIndicator{padding-left: 5px;    color: #808080;}

.MuiInput-root{
      border: 1px solid #ced4da!important;
      border-radius: .25rem!important;
      padding: 1.9px .75rem!important;
      margin-top: -1.5px!important;
}

.main{min-height:calc(100% - 66px)}

.form-check-input:disabled{ opacity: .5;}
.form-check-input:disabled ~ label span {opacity: .5;}

option[value=new_driver] {
  color: #999999;;
}

.row.main>.col-md-2{
  padding-left: 0;
}
@media (min-width: 981px){
  #leftMenusContainer{padding: 10px;}
  nav .svg-inline--fa{font-size: 20px}
}

@media (min-width: 768px){
  .left-menu {
    display: block!important;
  }
  .hide-desktop{display: none!important;}

}

@media (max-width: 766px){
  .main{min-height:auto!important}
  .left-menu {
    position: absolute;
    z-index: 9;
    width:100%;
    left: 0;
  }
  .navbar .navbar-brand{max-width: 70%}
  .navbar .navbar-brand img{max-width: 100%}
  #leftMenusContainer{background-color: #f9f9f9 !important;}
  .btn-confirm {
    min-width: unset;
  }
  .table-list{margin: 0!important;margin-bottom: 15px!important;}
  .title-mobile{font-size: 23px;}

}

